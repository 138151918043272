import request from '../../utils/request'
// 审批表单
export const getTotal = params => {
    return request({
        url: '/books/bookHome/categoryStatistics',
        method: 'GET',
        params
    })
}

// 近一周借阅情况
export const getWeekBorrowNum = params => {
    return request({
        url: '/books/bookHome/nearlyAWeekBorrow',
        method: 'GET',
        params
    })
}

// 热点图书
export const getTopBooks = params => {
    return request({
        url: '/books/bookHome/hotBookRanking',
        method: 'GET',
        params
    })
}

// 实时借阅
export const getActualBorrow = params => {
    return request({
        url: '/books/bookHome/realTime',
        method: 'GET',
        params
    })
}

// 班级借阅
export const getClassBorrow = params => {
    return request({
        url: '/books/bookHome/classBorrowing',
        method: 'GET',
        params
    })
}

// 读者排行
export const getTopReader = params => {
    return request({
        url: '/books/bookHome/readerRanking',
        method: 'GET',
        params
    })
}

// 数据概览
export const getOverView = params => {
    return request({
        url: '/books/bookHome/dataOverview',
        method: 'GET',
        params
    })
}

// 设备统计
export const getDeviceStatistics = params => {
    return request({
        url: '/healthy/healthyHome/deviceStatistics',
        method: 'GET',
        params
    })
}

// 商户情况
export const getMerchantSituations = params => {
    return request({
        url: '/healthy/healthyHome/merchantSituation',
        method: 'GET',
        params
    })
}

// 近一周刷卡情况
export const getWeekToSwipeYourCard = params => {
    return request({
        url: '/healthy/healthyHome/weekToSwipeYourCard',
        method: 'GET',
        params
    })
}

// 就餐人数/人次
export const getNumberOfDiners = params => {
    return request({
        url: '/healthy/healthyHome/numberOfDiners',
        method: 'GET',
        params
    })
}

// 就餐情况
export const getDiningConditions= params => {
    return request({
        url: '/healthy/healthyHome/diningConditions',
        method: 'GET',
        params
    })
}

// 消费情况
export const getConsumption= params => {
    return request({
        url: '/healthy/healthyHome/consumption',
        method: 'GET',
        params
    })
}

// 班级消费情况
export const getClassConsumption= params => {
    return request({
        url: '/healthy/healthyHome/classConsumption',
        method: 'GET',
        params
    })
}




