<template>
    <div class="pieChart">
        <div class="sin">
            <p class="num blue">
                <span class="min blue">{{this.info.breakfast}}</span>
                <span class="max">/{{this.info.total}}</span>
            </p>
            <div ref="breakfast" class="breakfast">
            
            </div>
            <div class="bot">
                <img src="../../../assets/images/base_breakfast@2x.png" alt="">
                <p class="txt">早餐</p>
            </div>
        </div>

        <div class="sin">
            <p class="num blue">
                <span class="min org">{{this.info.lunch}}</span>
                <span class="max">/{{this.info.total}}</span>
            </p>
            <div ref="lunch" class="breakfast">
            
            </div>
            <div class="bot">
                <img src="../../../assets/images/base_breakfast@2x.png" alt="">
                <p class="txt">午餐</p>
            </div>
        </div>

        <div class="sin">
            <p class="num blue">
                <span class="min red">{{this.info.dinner}}</span>
                <span class="max">/{{this.info.total}}</span>
            </p>
            <div ref="supper" class="breakfast">
            
            </div>
            <div class="bot">
                <img src="../../../assets/images/base_breakfast@2x.png" alt="">
                <p class="txt">晚餐</p>
            </div>
        </div>
    </div>
</template>

<script>
import {getDiningConditions} from '../../../api/index.js'
export default {
    name: '',
    props: {
        syncId: String
    },
    data() {
        return {
            info: {}
        }
    },
    methods: {
        setChart(value,color,dom) {
            let data = {
                value: this.info.total,
                name: '完成率'
            }
            let option = {
                // backgroundColor: '#04243E',
                title: {
                    // text: '{num|' + data.value + '}{key| %}',
                    // subtext: data.name,
                    // x: '49%',
                    // y: '46%',
                    textAlign: 'center',
                    textStyle: {
                        rich: {
                            num: {
                                fontWeight: 'bold',
                                color: '#fff',
                                fontFamily: '微软雅黑',
                                fontSize: 25,
                            },
                            key: {
                                fontWeight: 'bold',
                                color: '#fff',
                                fontFamily: '微软雅黑',
                                fontSize: 15,
                            }
                        }

                    },
                    subtextStyle: {
                        lineHeight: 30,
                        fontSize: 15
                    }
                },
                data: [{
                    name: data.name,
                }],
                series: [{ // 主圆环
                    name: data.name,
                    type: 'pie',
                    radius: ['70%', '90%'],
                    center: ['50%', '50%'],
                    startAngle: 225,
                    color: [{
                        type: 'linear',
                        x: 1,
                        y: 0,
                        x2: 0,
                        y2: 0,
                        colorStops: [{
                            offset: 0,
                            color: color[0] // 0% 处的颜色
                        }, {
                            offset: 1,
                            color: color[1] // 100% 处的颜色
                        }]
                    }, 'transparent'],
                    hoverAnimation: true,
                    legendHoverLink: false,
                    z: 10,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    data: [{
                        value: value < this.info.total ?  value/this.info.total * 225 / 360 : 0.75
                    }, {
                        value: 1 - (value/this.info.total) > 0.2 ?  1 - (value/this.info.total) : 0.25
                    }]
                }, { // 背景圆环
                    name: '',
                    type: 'pie',
                    radius: ['70%', '90%'],
                    center: ['50%', '50%'],
                    silent: true,
                    startAngle: 225,
                    labelLine: {
                        normal: {
                            show: false
                        }
                    },
                    z: 5,
                    data: [{
                        value: 75,
                        itemStyle: {
                            color: 'rgba(62,243,255,0.1)'
                        }
                    }, {
                        value: 25,
                        itemStyle: {
                            color: 'transparent'
                        }
                    }]
                }, ]
            };
            let myChart = this.$echarts(this.$refs[dom]);
            myChart.clear();
            myChart.resize()
            myChart.setOption(option);
            // let obj = {
            //     type: 'highlight',
            //     name: []
            // };
            // for (let i = 0; i < this.data.data.length - 1; i++) {
            //     obj.name.push(this.data.data[i].name)
            // }
            // myChart.dispatchAction(obj);
            // myChart.on('mouseout', function () {
            //     myChart.dispatchAction(obj);
            // });
        },
    },
    mounted() {
        let arr = [{data: 50,color:["rgba(62,243,255,1)","rgba(62,243,255,0.3)"]},{data: 60,color:["rgba(255, 205, 62, 1)","rgba(255, 205, 62,0.3)"]},{data: 70,color:["rgba(250, 75, 39, 1)","rgba(250, 75, 39,0.3)"]}];
        console.log(arr)
        // setTimeout(() => {
        //     this.setChart(arr[0].data, arr[0].color, "breakfast");
        //     this.setChart(arr[1].data, arr[1].color, "lunch");
        //     this.setChart(arr[2].data, arr[2].color, "supper");
        // }, 100)
        getDiningConditions({syncId: this.syncId}).then(res => {
            this.info = res.data
            this.setChart(this.info.breakfast, arr[0].color, "breakfast");
            this.setChart(this.info.lunch, arr[1].color, "lunch");
            this.setChart(this.info.dinner, arr[2].color, "supper");
        })
    },
}
</script>

<style lang="less" scoped>
.pieChart {
    width: 100%;
    height: 100%;
    display: flex;
    .sin{
        // flex: 1;
        width: 33%;
        height: 100%;
        position: relative;
        .breakfast,.lunch,.supper{
            width: 100%;
            height: 100%;
        }
        .num{
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .max{
                font-size: 0.7em;
                color: #fff;
                padding-left: 2px;
            }
            .min{
                font-size: 1.6em;
            }
            .blue {
                color: #3EF3FF;
            }
            .org {
                color: #FFCD3E;
            }
            .red{
                color: #FF4A22;
            }
        }
        .bot{
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                height: 2.8em;
                width: auto;
            }
            .txt{
                color: #fff;
                font-size: 1.2em;
                line-height: 4em;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: center
            }
        }
    }
}
</style>