import axios from 'axios';
let baseURL = "/prod-api"
//  process.env.NODE_ENV == 'production' ? '生产环境' : '开发环境'
 // 创建基础地址
 const service = axios.create({
   baseURL
 })
 service.interceptors.request.use(
  config => {
    // if (store.getters.token) {
    //   config.headers['apiToken'] = getToken()
    // }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
 service.interceptors.response.use(
  res => {
    if (res.status === 200) {
      return res.data;
    } else {
      Promise.reject(res.data);
    }
  },
  err => {
    console.log(err);
    return Promise.reject(err);
  }
);
export default service;